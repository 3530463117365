<template>
  <div>
    <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
    <b-row>
      
      <b-col lg="6">
        <b-card title="Formulário de Importação">
          <b-form-group
            label="Selecione o tipo de importação:"
            label-for="i-nome"
            class="pl-1 pr-1"
          >
            <b-form-select
              :options="options"
              v-model="dataImport.pathAPI"
            />
          </b-form-group>
          <b-form-group
            label="Selecione uma planilha:"
            label-for="i-nome"
            class="pl-1 pr-1"
          >
            <b-form-file
              :disabled="dataImport.pathAPI == null"
              placeholder="Arquivo"
              v-model="excel"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              name="excel"
            />
          </b-form-group>
        </b-card>
        <b-card>
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                @click="importExcel"
              >
                Importar
              </b-button>
            </b-col>
            <b-col
              ><b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                block
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        
      </b-col>
      <b-col lg="6">
        <div class="text-center">
          <b-img
            fluid
            width="500"
            :src="require('@/assets/images/undraw/spreadsheets.svg')"
          />
        </div>
      </b-col>
    </b-row>
    </b-overlay>
  </div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    mapActions,
    mapState,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Variáveis Global
      isLoading: false,
      excel: null,

      dataImport: {
        pathAPI: null,
        usuario_id: 1,
      },

       options: [
        { value: 'orcamento', text: 'Orçamento' },
        { value: 'resultado', text: 'Resultado' },
        { value: 'iap', text: 'IAP' },
      ],

      logMensage: {},

      listSelect: [],

      showLog: false,

      //Paginação
      perPage: 9,
      currentPage: 1,
      totalRows: null,
    };
  },
  computed: {
    ...mapState("connectAPI", ["modules"]),
  },
  methods: {
    importExcel() {
	  this.showLog = false;
      this.isLoading = true;

      this.dataImport.excel = this.excel;
      this.$store
        .dispatch("connectAPI/import_data", 
          this.dataImport,
        )
        .then((response) => {
          this.showLog = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Sistema de Importação!",
              icon: "ThumbsUpIcon",
              text: "Operação executada com sucesso.",
              variant: "success",
            },
          });
          
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    // this.fetchModules();
  },
  watch: {},
};
</script>
<style lang="scss">
.log-style {
  width: 100%;
  height: 600px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(75, 74, 74, 0.473);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(27, 27, 27);
  border-radius: 10px;
}
.text-format {
  width: 2.3ch;
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
</style>
